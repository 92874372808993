import Form from '@rjsf/core';
import { navigate } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import FormsModule from '../donation/forms.model';

import './Banner.scss';
import '../Navbar.scss';

const Banner = ({ image, title, subtitle }) => {
  const backgroundImage = `url(${!!image.childImageSharp ? image.childImageSharp.fluid.src : image})`;
  const uiSchema = {
    values: {
      'ui:widget': FormsModule.valuesUiSchema,
      'ui:options': {
        noRadioText: true
      }
    }
  }
  const schema = {
    type: 'object',
    properties: {
      values: {
        type: 'string',
        enumv: ['50', '100', '500', '1000', 'outro valor'],
        default: 'outro valor'
      }
    }
  };
  const onSubmit = (_schemaEvent, _event) => {
    navigate('https://doe.amigosdapoli.com.br')
  };
  return (
    <div
      id="home-banner"
      className="full-width-image margin-top-0 banner-background"
      style={{ backgroundImage }}
    >
      <div className="title-div-subheader">
        <h1 className="title-header">{title}</h1>
        <h2 className="title-subheader">
          {subtitle}
          {title === 'SOMOS O FUNDO PATRIMONIAL' ? (
            <>
              AMIGOS DA POLI&nbsp;&nbsp;&nbsp;
              <div className="has-text-centered">
                <Form schema={schema} uiSchema={uiSchema} onSubmit={onSubmit} onError={console.error}>
                  <button type="submit">quero doar!</button>
                </Form>
              </div>
            </>
          ) : (
            <></>
          )}
        </h2>
      </div>
    </div>
  );
};

Banner.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

export default Banner;
