import { range } from 'lodash';
import React, { useState } from 'react';
import InputMask from 'react-input-mask';
const groups = {
  undefined: [],
  no_campain: ['no_campaign_group'],
  entidades_poli: [
    'Professores',
    'Funcionários',
    'Accapoli',
    'Argo Biomédica',
    'AAAP',
    'AEQ',
    'CAM',
    'CMR',
    'CAEA',
    'CAEP',
    'CAPS',
    'CEE',
    'CEC',
    'CEN',
    'Centro de Carreira',
    'Concreto Poli',
    'Elas Pelas Exatas',
    'Equipe Poli de Baja',
    'Equipe Poli Racing (EPR)',
    'Equipe PoliMilhagem',
    'Escritório Piloto',
    'GTP',
    'Turing USP',
    'Grupo de Negócios',
    'Grêmio Politécnico',
    'Keep Flying',
    'LePoli',
    'Matemática em Movimento',
    'NEU',
    'PET Mecatrônica',
    'PET Mecânica',
    'Poli Consulting Club',
    'Poli Finance',
    'Poli Náutico',
    'Projeto Jupiter',
    'Rateria',
    'Skyrats',
    'ThundeRatz'
  ],
  mercado_financeiro: [
    'Poli Júnior',
    'Ambev',
    'P3urb',
  ],
  pessoa_fisica: [
    "Matching Diego Martins",
    "Pessoa Física"
  ],
};

const groupsName = {
  undefined: 'Selecione uma categoria',
  entidades_poli: 'Grupo',
  mercado_financeiro: 'Empresa',
  pessoa_fisica: 'Nome',
};

const outsidePoli = 'Não cursei engenharia na Poli';
const courses = [
  'Engenharia Ambiental',
  'Engenharia Civil',
  'Engenharia de Computação',
  'Engenharia de Materiais',
  'Engenharia de Minas',
  'Engenharia de Petróleo',
  'Engenharia de Produção',
  'Engenharia Elétrica',
  'Engenharia Mecânica',
  'Engenharia Mecatrônica',
  'Engenharia Metalurgica',
  'Engenharia Naval',
  'Engenharia Química',
];

const getStep1Configuration = (props) => {
  const properties = {
    values: {
      type: 'string',
      title: 'Valor',
      enumv: props.content.values,
      // To be more dinamic, I'm using this prop to set required array below
      // But, using only boolean true value, RJSF doesn't like
      // So, this is the accept format :shrug:
      required: ['values'],
    },

    frequency: {
      type: 'string',
      title: 'Periodicidade',
      enum: props.content.frequencies.map((f) => f.id),
      enumNames: props.content.frequencies.map((f) => f.text),
      required: ['frequency']
    },
    name: {
      type: 'string',
      title: 'Nome completo',
      minLength: 3,
      required: ['name']
    },
    email: {
      type: 'string',
      title: 'Email',
      format: 'email',
      required: ['email']
    },
    cpf: {
      type: 'string',
      title: 'CPF',
      required: ['cpf']
    },
    phone: {
      type: 'string',
      title: 'Telefone',
      required: ['phone']
    },
    course: {
      type: 'string',
      title: 'Você cursou alguma engenharia na Poli?',
      enum: [
        outsidePoli,
        ...courses
      ],
      required: ['course']
    },
    referral: {
      type: 'string',
      title: 'Como você conheceu o Amigos da Poli?',
      maxLength: 255
    },
  };

  const dependencies = {
    course: {
      oneOf: [
        {
          properties: {
            course: {
              enum: courses
            },
            conclusion_year: {
              type: 'number',
              title: 'Qual seu ano de formatura?',
              enum: range(new Date().getFullYear() + 5, 1950, -1).map((y) => y),
            },
          }
        },
        {
          properties: {
            course: {
              enum: [outsidePoli]
            }
          }
        }
      ]
    }
  };

  return{
    properties,
    schema: {
      title: props.content.step1.SubTitle,
      type: 'object',
      required: Object.keys(properties).filter(p => (p.required || [])[0]),
      properties,
      dependencies
    },
  };
};
const getStep2Configuration = (props) => {
  const properties = {
    card_number: {
      type: 'string',
      title: 'Preencha o número do seu cartão de crédito',
      minLength: 16,
    },
    name_on_card: {
      type: 'string',
      title: 'Nome do titular',
    },
    expire_date: {
      type: 'string',
      title: 'Data de validade',
    },
    security_code: {
      type: 'string',
      title: 'Código de segurança CVV',
      minLength: 3,
      manLength: 3,
    },
    chosen_value: {
      type: 'string',
      title: 'Valor',
      enum: [props.formData.chosen_value],
    },
    chosen_frequency: {
      type: 'string',
      title: 'Periodicidade',
      enum: [props.formData.frequency],
      enumNames: [props.content.frequencies.map((f) => {
        if (props.formData.frequency === f.id) {
          return f.text;
        }
      })],
    },
  };

  return {
    properties,
    schema: {
      title: props.content.step2.SubTitle,
      type: 'object',
      required: Object.keys(properties),
      properties,
    },
  };
};

const ValuesUiSchema = (props) => {
  const { options, value, required, disabled, readonly, autofocus, onBlur, onFocus, onChange, id } = props;
  const name = Math.random().toString();
  const { enumOptions, enumDisabled, inline, noRadioText } = options;
  const [textOptionChecked, setTextOptionCheck] = useState(false);
  const selectTextOption = () => {
    if(textOptionChecked == true) return;

    onChange('');
    setTextOptionCheck(true);
  };

  return (
    <div className="field-radio-group" id={id}>
      {props.schema.enumv.map((option, i) => {
        const checked = option === value;
        const itemDisabled = enumDisabled && enumDisabled.indexOf(option) != -1;
        const monetizeValue = (value) => isNaN(value) ? value : `R$ ${value}`;
        const disabledCls = disabled || itemDisabled || readonly ? 'disabled' : '';
        const radio = (
          <span>
            <input
              type="radio"
              checked={checked}
              name={name}
              required={required}
              value={option}
              disabled={disabled || itemDisabled || readonly}
              autoFocus={autofocus && i === 0}
              onChange={(_) => { onChange(option); setTextOptionCheck(false); }}
              onBlur={onBlur && ((event) => onBlur(id, event.target.value))}
              onFocus={onFocus && ((event) => onFocus(id, event.target.value))}
            />

            <span>{monetizeValue(option)}</span>
          </span>
        );
        const radioText = (
          <span>
            <input
              type="radio"
              checked={(checked || (props.schema.enumv.indexOf(value) == -1) || textOptionChecked)}
              name={name}
              required={required}
              value={option}
              disabled={disabled || itemDisabled || readonly}
              autoFocus={autofocus && i === 0}
              onChange={(_) => { onChange('');  setTextOptionCheck(true); }}
              onBlur={onBlur && ((event) => onBlur(id, event.target.value))}
              onFocus={onFocus && ((event) => onFocus(id, event.target.value))}
            />

            <input
              autoFocus={autofocus && i === 0}
              disabled={false}
              name={name + '_text'}
              onBlur={onBlur && ((event) => onBlur(id, event.target.value))}
              onChange={(e) => onChange(e.target.value)}
              onFocus={onFocus && ((event) => onFocus(id, event.target.value))}
              onClick={(_) =>  selectTextOption()}
              required={required}
              type="text"
              value={textOptionChecked ? value : '' }
              className="smaller-text"
              placeholder="Digite outro valor. Ex: 150"
            />
          </span>
        );
        let input = noRadioText ? radio : i === props.schema.enumv.length - 1 ? radioText : radio;
        return inline ? (
          <label key={i} className={`radio-inline ${disabledCls}`}>
            {input}
          </label>
        ) : (
          <div key={i} className={`radio ${disabledCls}`}>
            <label>{input}</label>
          </div>
        );
      })}
    </div>
  );
};

const uiSchema = {
  'ui:order': [
    'values',
    'frequency',
    'name',
    'email',
    'cpf',
    'phone',
    'course',
    'conclusion_year',
    'referral',
    'card_number',
    'name_on_card',
    'expire_date',
    'security_code',
    'chosen_value',
    'chosen_frequency',
  ],
  referral: {
    'ui:widget': 'textarea',
    'ui:options': {
      inputType: 'text'
    }
  },
  email: {
    'ui:options': {
      inputType: 'email',
    },
  },
  cpf: {
    'ui:widget': (props) => {
      return <InputMask {...props} mask="999.999.999-99" maskChar={null} onChange={(e) => props.onChange(e.target.value)} />;
    },
  },
  phone: {
    'ui:widget': (props) => {
      const beforeMaskedValueChange = (newState) => {
        let { value } = newState;

        const newValue = value.replace(/\D/g, "");
        if (newValue.length === 11) {
          value = newValue.replace(/^(\d{2})(\d{1})(\d{4})(\d{4})$/, "($1) $2 $3-$4");
        }

        return {
          ...newState,
          value
        };
      };

      return <InputMask {...props} mask="(99) 9999-99999" beforeMaskedValueChange={beforeMaskedValueChange} maskChar={null} onChange={(e) => props.onChange(e.target.value)} />;
    },
  },
  card_number: {
    'ui:widget': (props) => {
      return <InputMask {...props} mask="9999 9999 9999 9999" maskChar={null} onChange={(e) => props.onChange(e.target.value)} />;
    },
  },
  expire_date: {
    'ui:widget': (props) => {
      const placeholder = 'mm/yy';
      const formatChars = {
        n: '[0-1]',
        m: '[0-9]',
        z: '[1-2]',
        y: '[0-9]',
      };
      return <InputMask {...props} mask="nm/zy" maskChar={null} formatChars={formatChars} placeholder={placeholder} onChange={(e) => props.onChange(e.target.value)} />;
    },
  },
  security_code: {
    'ui:widget': (props) => {
      return <InputMask {...props} mask="999" maskChar={null} onChange={(e) => props.onChange(e.target.value)} />;
    },
  },
  values: {
    'ui:widget': ValuesUiSchema,
  },
  frequency: {
    'ui:widget': 'radio',
  },
  chosen_value: {
    'ui:widget': 'radio',
  },
  chosen_frequency: {
    'ui:widget': 'radio',
  },
};

const transformErrors = (errors) => {
  return errors.map(error => {
    if (error.name === 'maxLength') {
      error.message = `Não deve ter mais que ${error.params.limit} caracteres.`;
    }
    return error;
  });
};

export default {
  getStep1Configuration,
  getStep2Configuration,
  transformErrors,
  uiSchema,
  valuesUiSchema: ValuesUiSchema,
};
